/* ------------------------------------------------------------------ */
/* e. publications Section
/* ------------------------------------------------------------------ */

#publications {
    background: #ebeeee;
    padding-top: 30px;
    padding-bottom: 60px;
}

#publications h1 {
    font: 42px 'raleway-bold', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 48px;
    color: #95a3a3;
    text-decoration: underline;
}

/* publications Content */
#publications-wrapper .columns {
    margin-bottom: 36px;
}

.publications-item .item-wrap {
    background: #fff;
    overflow: hidden;
    position: relative;
    height: 200px;

    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.publications-item .item-wrap img {
    margin-left: 25px;
    margin-top: 25px;

    vertical-align: bottom;
}

.publications-item .item-wrap a {
    display: block;
    cursor: pointer;
}

/* overlay */
.publications-item .item-wrap .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    opacity: 1;
    -moz-opacity: 1;
    filter: alpha(opacity=1);

    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;

    /* background: url(../images/overlay-bg.png) repeat; */

    background: rgba(255, 255, 255, 0.75);
}

.publications-item .item-wrap .link-icon {
    display: block;
    color: #000;
    height: 30px;
    width: 30px;
    font-size: 18px;
    line-height: 30px;
    text-align: center;

    opacity: 1;
    -moz-opacity: 1;
    filter: alpha(opacity=1);

    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;

    position: absolute;
    top: calc(100% - 20px);
    left: calc(100% - 20px);
    margin-left: -15px;
    margin-top: -15px;
}

.publications-item .publications-item-meta {
    padding: 18px;
}

.publications-item .publications-item-meta h5 {
    font: 14px/20px 'raleway-bold', sans-serif;
    color: #000;

    font-weight: bold;
}

.publications-item .publications-item-meta p {
    font: 12px/18px 'raleway-light', sans-serif;
    color: #838585;
    margin-bottom: 0;
}

/* on hover
 .publications-item:hover .overlay {
     opacity: 1;
     -moz-opacity: 1;
     filter:alpha(opacity=100);
 }
 .publications-item:hover .link-icon {
    opacity: 1;
     -moz-opacity: 1;
     filter:alpha(opacity=100);
 }
 
 /* popup modal 
 .popup-modal {
     max-width: 550px;
     background: #fff;
     position: relative;
     margin: 0 auto;
 }
 .popup-modal .description-box { padding: 12px 36px 18px 36px; }
 .popup-modal .description-box h4 {
    font: 15px/24px 'opensans-bold', sans-serif;
     margin-bottom: 12px;
    color: #111;
 }
 .popup-modal .description-box p {
     font: 14px/24px 'opensans-regular', sans-serif;
    color: #A1A1A1;
    margin-bottom: 12px;
 }
 .popup-modal .description-box .categories {
    font: 11px/21px 'opensans-light', sans-serif;
    color: #A1A1A1;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: block;
    text-align: left;
 }
 .popup-modal .description-box .categories i {
    margin-right: 8px;
 }
 .popup-modal .link-box {
    padding: 18px 36px;
    background: #111;
    text-align: left;
 }
 .popup-modal .link-box a {
    color: #fff;
     font: 11px/21px 'opensans-bold', sans-serif;
     text-transform: uppercase;
    letter-spacing: 3px;
    cursor: pointer;
 }
 .popup-modal a:hover {	color: #00CCCC; }
 .popup-modal a.popup-modal-dismiss { margin-left: 24px; }
 */

/* fadein/fadeout effect for modal popup
 /* ------------------------------------------------------------------ */

/* .mfp-fade.mfp-wrap .mfp-content .popup-modal {
    opacity: 0;
    -webkit-transition: all 200ms ease-in-out;
     -moz-transition: all 200ms ease-in-out;
     -o-transition: all 200ms ease-in-out;
     -ms-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
 }


 .mfp-fade.mfp-wrap.mfp-ready .mfp-content .popup-modal {
    opacity: 1;
 }


 .mfp-fade.mfp-wrap.mfp-removing .mfp-content .popup-modal {
    opacity: 0;
 } */

