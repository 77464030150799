#ts-mule {
    background: #ffffff;
    margin-top: 30px;
    padding-bottom: 66px;
    overflow: hidden;
}

#ts-mule a,
#ts-mule a:visited {
    color: #000000;

    font: 1.3vw 'Raleway', sans-serif;
}

#ts-mule a:hover,
#ts-mule a:focus {
    color: #000000;
}

#ts-mule .divider {
    height: min(50px, 3vh);
}

#ts-mule .text-divider {
    width: 50px;
    display: inline-block;
}

#ts-mule h1 {
    font: 2.8vw 'Raleway', sans-serif;
    color: #000000;
    margin-bottom: 12px;
    text-align: center;
    font-weight: bold;
}

#ts-mule h2 {
    font: 2.2vw 'Raleway', sans-serif;
    color: #000000;
    margin-bottom: 12px;
    text-align: center;
    font-weight: bold;
}

#ts-mule h3 {
    font: 1.3vw 'Raleway', sans-serif;
    color: #000000;
    margin-bottom: 12px;
    text-align: center;
}

#ts-mule p, #ts-mule ul li {
    font: 1.2vw 'Raleway', sans-serif;
    color: #000000;
    font-weight: 300;
    text-align: justify;
}

#ts-mule .bold {
    font: 1.2vw 'Raleway', sans-serif;
    color: #000000;
    text-align: justify;
    font-weight: bold;
}

#ts-mule .references {
    font: 0.8vw 'Raleway', sans-serif;
    color: #000000;
    font-weight: 300;
    text-align: justify;
}

#ts-mule .logos {
    position: absolute;

}

#ts-mule .logos .logo,
#ts-mule .images-text {
    padding: 20px;
}

#ts-mule ul {
    list-style-type: disc;
    margin-left: 30px;
}

#ts-mule .return {
    position: absolute;
    left: 10px;
    top: 10px;
}