#about {
    background: #acdfe0;
    padding-top: 96px;
    padding-bottom: 66px;
    overflow: hidden;
}

#about a,
#about a:visited {
    color: #0c6774;
    text-decoration: underline;
}
#about a:hover,
#about a:focus {
    color: #ffffff;
}

#about h1 {
    font: 5em 'Raleway', sans-serif;
    color: #000000;
    margin-bottom: 12px;
}

#about h2 {
    font: 3em 'Raleway', sans-serif;
    color: #000000;
    margin-bottom: 12px;
}

#about p {
    font: 2em 'Raleway', sans-serif;
    color: #000000;
    font-weight: 300;
    /* width: 75%; */
    text-align: justify;
}

#about span.bold-highlight {
    font-weight: bolder;
    text-decoration: underline;
}

