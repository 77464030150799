@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*----------------------------------------------*/
/*	Skill Bars
/*----------------------------------------------*/

.bars {
	width: 95%;
	float: left;
	padding: 0;
	text-align: left;
}
.bars .skills {
  	margin-top: 36px;
   list-style: none;
}
.bars li {
   position: relative;
  	margin-bottom: 60px;
  	background: #ccc;
  	height: 42px;
  	border-radius: 3px;
}
.bars li em {
	font: 15px 'Raleway', sans-serif;
   color: #313131;
	text-transform: uppercase;
   letter-spacing: 2px;
	font-weight: normal;
   position: relative;
	top: -36px;
}
.bar-expand {
   position: absolute;
   left: 0;
   top: 0;

   margin: 0;
   padding-right: 24px;
  	background: #313131;
   display: inline-block;
  	height: 42px;
   line-height: 42px;
   border-radius: 3px 0 0 3px;
}
/*
.git {
  	-moz-animation: git 2s ease;
  	-webkit-animation: git 2s ease;
}
.illustrator {
  	-moz-animation: illustrator 2s ease;
  	-webkit-animation: illustrator 2s ease;
}
.reactjs {
  	-moz-animation: reactjs 2s ease;
  	-webkit-animation: reactjs 2s ease;
}
.css {
  	-moz-animation: css 2s ease;
  	-webkit-animation: css 2s ease;
}
.html5 {
  	-moz-animation: html5 2s ease;
  	-webkit-animation: html5 2s ease;
}
.vuejs {
  	-moz-animation: vuejs 2s ease;
  	-webkit-animation: vuejs 2s ease;
}
.mongodb {
  	-moz-animation: mongodb 2s ease;
  	-webkit-animation: mongodb 2s ease;
}

@-moz-keyframes git {
  0%   { width: 0px;  }
  100% { width: 60%;  }
}
@-moz-keyframes illustrator {
  0%   { width: 0px;  }
  100% { width: 55%;  }
}
@-moz-keyframes reactjs {
  0%   { width: 0px;  }
  100% { width: 50%;  }
}
@-moz-keyframes css {
  0%   { width: 0px;  }
  100% { width: 90%;  }
}
@-moz-keyframes html5 {
  0%   { width: 0px;  }
  100% { width: 80%;  }
}
@-moz-keyframes vuejs {
  0%   { width: 0px;  }
  100% { width: 50%;  }
}
@-moz-keyframes mongodb {
  0%   { width: 0px;  }
  100% { width: 80%;  }
}

@-webkit-keyframes photoshop {
  0%   { width: 0px;  }
  100% { width: 60%;  }
}
@-webkit-keyframes illustrator {
  0%   { width: 0px;  }
  100% { width: 55%;  }
}
@-webkit-keyframes reactjs {
  0%   { width: 0px;  }
  100% { width: 50%;  }
}
@-webkit-keyframes css {
  0%   { width: 0px;  }
  100% { width: 90%;  }
}
@-webkit-keyframes html5 {
  0%   { width: 0px;  }
  100% { width: 80%;  }
}
@-webkit-keyframes vuejs {
  0%   { width: 0px;  }
  100% { width: 50%;  }
}
@-webkit-keyframes mongodb {
  0%   { width: 0px;  }
  100% { width: 80%;  }
}  */

/* ------------------------------------------------------------------ */
/* f. Call To Action Section
/* ------------------------------------------------------------------ */

#call-to-action {
   background: #212121;
   padding-top: 66px;
   padding-bottom: 48px;
}
#call-to-action h1 {
   font: 18px/24px 'opensans-bold', sans-serif;
   text-transform: uppercase;
   letter-spacing: 3px;
   color: #fff;
}
#call-to-action h1 span { display: none; }
#call-to-action .header-col h1:before {
   font-family: 'Raleway', sans-serif;
   content: "\f0ac";
	padding-right: 10px;
	font-size: 72px;
   line-height: 72px;
   text-align: left;
   float: left;
   color: #fff;
}
#call-to-action .action {
   margin-top: 12px;
}
#call-to-action h2 {
   font: 28px/36px 'Raleway', sans-serif;
   color: #EBEEEE;
   margin-bottom: 6px;
}
#call-to-action h2 a {
   color: inherit;
}
#call-to-action p {
   color: #636363;
   font-size: 17px;
}
/*#
call-to-action .button {
	color:#fff;
   background: #0D0D0D;
}
*/
#call-to-action .button:hover,
#call-to-action .button:active {
   background: #FFFFFF;
   color: #0D0D0D;
}
#call-to-action p span {
	font-family: 'opensans-semibold', sans-serif;
	color: #D8D8D8;
}

/* ------------------------------------------------------------------
/* g. Testimonials
/* ------------------------------------------------------------------ */

#testimonials {
   background: #1F1F1F;
   background-size: cover !important;
	-webkit-background-size: cover !important;
   background-attachment: fixed;

   position: relative;
   min-height: 200px;
   width: 100%;
   overflow: hidden;
}
#testimonials .text-container {
   padding-top: 96px;
   padding-bottom: 66px;
}
#testimonials h1 {
   font: 18px/24px 'opensans-bold', sans-serif;
   text-transform: uppercase;
   letter-spacing: 3px;
   color: #fff;
}
#testimonials h1 span { display: none; }
#testimonials .header-col { padding-top: 9px; }
#testimonials .header-col h1:before {
   font-family: 'FontAwesome';
   content: "\f10d";
	padding-right: 10px;
	font-size: 72px;
   line-height: 72px;
   text-align: left;
   float: left;
   color: #fff;
}

/*	Blockquotes */
#testimonials blockquote {
   margin: 0 0px 30px 0px;
   padding-left: 0;
   position: relative;
   text-shadow: 0px 1px 3px rgba(0, 0, 0, 1);
}
#testimonials blockquote:before { content: none; }
#testimonials blockquote p {
   font-family: 'librebaskerville-italic', serif;
   padding: 0;
   font-size: 24px;
   line-height: 48px;
   color: #fff
}
#testimonials blockquote cite {
   display: block;
   font-size: 12px;
   font-style: normal;
   line-height: 18px;
   color: #fff;
}
#testimonials blockquote cite:before { content: "\2014 \0020"; }
#testimonials blockquote cite a,
#testimonials blockquote cite a:visited { color: #8B9798; border: none }

/* Flex Slider
/* ------------------------------------------------------------------ */

/* Reset */
.flexslider a:active,
.flexslider a:focus  { outline: none; }
.slides,
.flex-control-nav,
.flex-direction-nav { margin: 0; padding: 0; list-style: none; }
.slides li { margin: 0; padding: 0;}

/* Necessary Styles */
.flexslider {
   position: relative;
   zoom: 1;
   margin: 0;
   padding: 0;
}
.flexslider .slides { zoom: 1; }
.flexslider .slides > li { position: relative; }

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides > li { display: none; -webkit-backface-visibility: hidden; }
/* Suggested container for slide animation setups. Can replace this with your own */
.flex-container { zoom: 1; position: relative; }

/* Clearfix for .slides */
.slides:before,
.slides:after {
   content: " ";
   display: table;
}
.slides:after {
   clear: both;
}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child { display: block; }

/* Slider Styles */
.slides { zoom: 1; }
.slides > li {
   /*margin-right: 5px; */
   overflow: hidden;
}

/* Control Nav */
.flex-control-nav {
    width: 100%;
    position: absolute;
    bottom: -20px;
    text-align: left;
}
.flex-control-nav li {
    margin: 0 6px;
    display: inline-block;
    zoom: 1;
    *display: inline;
}
.flex-control-paging li a {
    width: 12px;
    height: 12px;
    display: block;
    background: #ddd;
    background: rgba(255, 255, 255, .3);
    cursor: pointer;
    text-indent: -9999px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -o-border-radius: 20px;
    border-radius: 20px;
    box-shadow: inset 0 0 3px rgba(255, 255, 255, .3);
}
.flex-control-paging li a:hover {
    background: #CCC;
    background: rgba(255, 255, 255, .7);
}
.flex-control-paging li a.flex-active {
    background: #fff;
    background: rgba(255, 255, 255, .9);
    cursor: default;
}

/* ------------------------------------------------------------------ */
/* h. Contact Section
/* ------------------------------------------------------------------ */

#contact {
   background: #191919;
   padding-top: 96px;
   padding-bottom: 102px;
   color: #636363;
}
#contact .section-head { margin-bottom: 42px; }

#contact a, #contact a:visited  { color: #11ABB0; }
#contact a:hover, #contact a:focus { color: #fff; }

#contact h1 {
   font: 18px/24px 'opensans-bold', sans-serif;
   text-transform: uppercase;
   letter-spacing: 3px;
   color: #EBEEEE;
   margin-bottom: 6px;
}
#contact h1 span { display: none; }
#contact h1:before {
   font-family: 'FontAwesome';
   content: "\f0e0";
	padding-right: 10px;
	font-size: 72px;
   line-height: 72px;
   text-align: left;
   float: left;
   color: #ebeeee;
}

#contact h4 {
   font: 16px/24px 'opensans-bold', sans-serif;
   color: #EBEEEE;
   margin-bottom: 6px;
}
#contact p.lead {
   font: 18px/36px 'opensans-light', sans-serif;
   padding-right: 3%;
}
#contact .header-col { padding-top: 6px; }


/* contact form */
#contact form { margin-bottom: 30px; }
#contact label {
   font: 15px/24px 'opensans-bold', sans-serif;
   margin: 12px 0;
   color: #EBEEEE;
	display: inline-block;
	float: left;
   width: 26%;
}
#contact input,
#contact textarea,
#contact select {
   padding: 18px 20px;
	color: #eee;
	background: #373233;
	margin-bottom: 42px;
	border: 0;
	outline: none;
   font-size: 15px;
   line-height: 24px;
   width: 65%;
}
#contact input:focus,
#contact textarea:focus,
#contact select:focus {
	color: #fff;
	background-color: #11ABB0;
}
#contact button.submit {
	text-transform: uppercase;
	letter-spacing: 3px;
	color:#fff;
   background: #0D0D0D;
	border: none;
   cursor: pointer;
   height: auto;
   display: inline-block;
	border-radius: 3px;
   margin-left: 26%;
}
#contact button.submit:hover {
	color: #0D0D0D;
	background: #fff;
}
#contact span.required {
	color: #11ABB0;
	font-size: 13px;
}
#message-warning, #message-success {
   display: none;
	background: #0F0F0F;
	padding: 24px 24px;
	margin-bottom: 36px;
   width: 65%;
   margin-left: 26%;
}
#message-warning { color: #D72828; }
#message-success { color: #11ABB0; }

#message-warning i,
#message-success i {
   margin-right: 10px;
}
#image-loader {
   display: none;
   position: relative;
   left: 18px;
   top: 17px;
}


/* Twitter Feed */
#twitter {
   margin-top: 12px;
   padding: 0;
}
#twitter li {
   margin: 6px 0px 12px 0;
   line-height: 30px;
}
#twitter li span {
   display: block;
}
#twitter li b a {
   font: 13px/36px 'opensans-regular', Sans-serif;
   color: #474747 !important;
   border: none;
}


/* ------------------------------------------------------------------ */
/* i. Footer
/* ------------------------------------------------------------------ */

footer {
   padding-top: 48px;
   margin-bottom: 48px;
   color: #303030;
   font-size: 14px;
   text-align: center;
   position: relative;
}

footer a, footer a:visited { color: #525252; }
footer a:hover, footer a:focus { color: #fff; }

/* copyright */
footer .copyright {
    margin: 0;
    padding: 0;
 }
footer .copyright li {
    display: inline-block;
    margin: 0;
    padding: 0;
    line-height: 24px;
}
.ie footer .copyright li {
   display: inline;
}
footer .copyright li:before {
    content: "\2022";
    padding-left: 10px;
    padding-right: 10px;
    color: #095153;
}
footer .copyright  li:first-child:before {
    display: none;
}

/* social links */
footer .social-links {
   margin: 18px 0 30px 0;
   padding: 0;
   font-size: 30px;
}
footer .social-links li {
    display: inline-block;
    margin: 0;
    padding: 0;
    margin-left: 42px;
    color: #F06000;
}

footer .social-links li:first-child { margin-left: 0; }

/* Go To Top Button */
#go-top {
	position: absolute;
	top: -24px;
   left: 50%;
   margin-left: -30px;
}
#go-top a {
	text-decoration: none;
	border: 0 none;
	display: block;
	width: 60px;
	height: 60px;
	background-color: #525252;

	-webkit-transition: all 0.2s ease-in-out;
   -moz-transition: all 0.2s ease-in-out;
   -o-transition: all 0.2s ease-in-out;
   -ms-transition: all 0.2s ease-in-out;
   transition: all 0.2s ease-in-out;

   color: #fff;
   font-size: 21px;
   line-height: 60px;
 	border-radius: 100%;
}
#go-top a:hover { background-color: #0F9095; }

/*
=====================================================================
*   Ceevee v1.0 Default Stylesheet
*   url: styleshout.com
*   03-17-2014
=====================================================================
TOC:
a. Webfonts and Icon fonts
b. Reset
c. Default Styles
   1. Basic
   2. Typography
   3. Links
   4. Images
   5. Buttons
   6. Forms
d. Grid
e. Others
   1. Clearing
   2. Misc
=====================================================================  */

/* ------------------------------------------------------------------ */
/* a. Webfonts and Icon fonts
 ------------------------------------------------------------------ */

 /* @import url("fonts.css");
 @import url("fontello/css/fontello.css");
 @import url("font-awesome/css/font-awesome.min.css"); */
 
 /* ------------------------------------------------------------------
 /* b. Reset
       Adapted from:
       Normalize.css - https://github.com/necolas/normalize.css/
       HTML5 Doctor Reset - html5doctor.com/html-5-reset-stylesheet/
 /* ------------------------------------------------------------------ */
 
 html, body, div, span, object, iframe,
 h1, h2, h3, h4, h5, h6, p, blockquote, pre,
 abbr, address, cite, code,
 del, dfn, em, img, ins, kbd, q, samp,
 small, strong, sub, sup, var,
 b, i,
 dl, dt, dd, ol, ul, li,
 fieldset, form, label, legend,
 table, caption, tbody, tfoot, thead, tr, th, td,
 article, aside, canvas, details, figcaption, figure,
 footer, header, hgroup, menu, nav, section, summary,
 time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
 }
 
 article,aside,details,figcaption,figure,
 footer,header,hgroup,menu,nav,section {
    display: block;
 }
 
 audio,
 canvas,
 video {
    display: inline-block;
 }
 
 audio:not([controls]) {
    display: none;
    height: 0;
 }
 
 [hidden] { display: none; }
 
 code, kbd, pre, samp {
    font-family: monospace, serif;
    font-size: 1em;
 }
 
 pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
 }
 
 blockquote, q { quotes: &#8220 &#8220; }
 
 blockquote:before, blockquote:after,
 q:before, q:after {
    content: '';
    content: none;
 }
 
 ins {
    background-color: #ff9;
    color: #000;
    text-decoration: none;
 }
 
 mark {
    background-color: #A7F4F6;
    color: #555;
 }
 
 del { text-decoration: line-through; }
 
 abbr[title], dfn[title] {
    border-bottom: 1px dotted;
    cursor: help;
 }
 
 table {
    border-collapse: collapse;
    border-spacing: 0;
 }
 
 
 /* ------------------------------------------------------------------ */
 /* c. Default and Basic Styles
       Adapted from:
       Skeleton CSS Framework - http://www.getskeleton.com/
       Typeplate Typographic Starter Kit - http://typeplate.com/
 /* ------------------------------------------------------------------ */
 
 /*  1. Basic  ------------------------------------------------------- */
 
 *,
 *:before,
 *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
 }
 
 html {
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
 }
 
 body {
    background: #fff;
    font-family: 'opensans-regular', sans-serif;
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;
    color: #838C95;
 
    -webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
    -webkit-text-size-adjust: 100%;
 }
 
 /*  2. Typography
        Vertical rhythm with leading derived from 6
 --------------------------------------------------------------------- */
 
 h1, h2, h3, h4, h5, h6 {
    color: #313131;
    font-family: 'opensans-bold', sans-serif;
    font-weight: normal;
 }
 h1 a, h2 a, h3 a, h4 a, h5 a, h6 a { font-weight: inherit; }
 h1 { font-size: 38px; line-height: 42px; margin-bottom: 12px; letter-spacing: -1px; }
 h2 { font-size: 28px; line-height: 36px; margin-bottom: 6px; }
 h3 { font-size: 22px; line-height: 30px; margin-bottom: 12px; }
 h4 { font-size: 20px; line-height: 30px; margin-bottom: 6px; }
 h5 { font-size: 18px; line-height: 30px; }
 h6 { font-size: 14px; line-height: 30px; }
 .subheader { }
 
 p { margin: 0 0 30px 0; }
 p img { margin: 0; }
 p.lead {
    font: 19px/36px 'opensans-light', sans-serif;
    margin-bottom: 18px;
 }
 
 /* for 'em' and 'strong' tags, font-size and line-height should be same with
 the body tag due to rendering problems in some browsers */
 em { font: 15px/30px 'opensans-italic', sans-serif; }
 strong, b { font: 15px/30px 'opensans-bold', sans-serif; }
 small { font-size: 11px; line-height: inherit; }
 
 /*	Blockquotes */
 blockquote {
    margin: 30px 0px;
    padding-left: 40px;
    position: relative;
 }
 blockquote:before {
    content: "\201C";
    opacity: 0.45;
    font-size: 80px;
    line-height: 0px;
    margin: 0;
    font-family: arial, sans-serif;
 
    position: absolute;
    top:  30px;
    left: 0;
 }
 blockquote p {
    font-family: 'librebaskerville-italic', serif;
    padding: 0;
    font-size: 18px;
    line-height: 36px;
 }
 blockquote cite {
    display: block;
    font-size: 12px;
    font-style: normal;
    line-height: 18px;
 }
 blockquote cite:before { content: "\2014 \0020"; }
 blockquote cite a,
 blockquote cite a:visited { color: #8B9798; border: none }
 
 /* ---------------------------------------------------------------------
 /*  Pull Quotes Markup
 /*
     <aside class="pull-quote">
       <blockquote>
          <p></p>
       </blockquote>
     </aside>
 /*
 /* --------------------------------------------------------------------- */
 .pull-quote {
    position: relative;
    padding: 18px 30px 18px 0px;
 }
 .pull-quote:before,
 .pull-quote:after {
    height: 1em;
    opacity: 0.45;
    position: absolute;
    font-size: 80px;
    font-family: Arial, Sans-Serif;
 }
 .pull-quote:before {
    content: "\201C";
    top:  33px;
    left: 0;
 }
 .pull-quote:after {
    content: '\201D';
    bottom: -33px;
    right: 0;
 }
 .pull-quote blockquote {
    margin: 0;
 }
 .pull-quote blockquote:before {
    content: none;
 }
 
 /* Abbreviations */
 abbr {
    font-family: 'opensans-bold', sans-serif;
    font-variant: small-caps;
    text-transform: lowercase;
    letter-spacing: .5px;
    color: gray;
 }
 abbr:hover { cursor: help; }
 
 /* drop cap */
 .drop-cap:first-letter {
    float: left;
    margin: 0;
    padding: 14px 6px 0 0;
    font-size: 84px;
    font-family: /* Copperplate */ 'opensans-bold', sans-serif;
    line-height: 60px;
    text-indent: 0;
    background: transparent;
    color: inherit;
 }
 
 hr { border: solid #E3E3E3; border-width: 1px 0 0; clear: both; margin: 11px 0 30px; height: 0; }
 
 
 /*  3. Links  ------------------------------------------------------- */
 
 a, a:visited {
    text-decoration: none;
    outline: 0;
    color: #11ABB0;
 
    -webkit-transition: color .3s ease-in-out;
    -moz-transition: color .3s ease-in-out;
    -o-transition: color .3s ease-in-out;
    transition: color .3s ease-in-out;
 }
 a:hover, a:focus { color: #313131; }
 p a, p a:visited { line-height: inherit; }
 
 
 /*  4. List  --------------------------------------------------------- */
 
 ul, ol { margin-bottom: 24px; margin-top: 12px; }
 ul { list-style: none outside; }
 ol { list-style: decimal; }
 ol, ul.square, ul.circle, ul.disc { margin-left: 30px; }
 ul.square { list-style: square outside; }
 ul.circle { list-style: circle outside; }
 ul.disc { list-style: disc outside; }
 ul ul, ul ol,
 ol ol, ol ul { margin: 6px 0 6px 30px; }
 ul ul li, ul ol li,
 ol ol li, ol ul li { margin-bottom: 6px; }
 li { line-height: 18px; margin-bottom: 12px; }
 ul.large li { }
 li p { }
 
 /* ---------------------------------------------------------------------
 /*  Stats Tab Markup
     <ul class="stats-tabs">
       <li><a href="#">[value]<b>[name]</b></a></li>
     </ul>
     Extend this object into your markup.
 /*
 /* --------------------------------------------------------------------- */
 .stats-tabs {
    padding: 0;
    margin: 24px 0;
 }
 .stats-tabs li {
    display: inline-block;
    margin: 0 10px 18px 0;
    padding: 0 10px 0 0;
    border-right: 1px solid #ccc;
 }
 .stats-tabs li:last-child {
    margin: 0;
    padding: 0;
    border: none;
 }
 .stats-tabs li a {
    display: inline-block;
    font-size: 22px;
    font-family: 'opensans-bold', sans-serif;
    border: none;
    color: #313131;
 }
 .stats-tabs li a:hover {
    color:#11ABB0;
 }
 .stats-tabs li a b {
    display: block;
    margin: 6px 0 0 0;
    font-size: 13px;
    font-family: 'opensans-regular', sans-serif;
    color: #8B9798;
 }
 
 /* definition list */
 dl { margin: 12px 0; }
 dt { margin: 0; color:#11ABB0; }
 dd { margin: 0 0 0 20px; }
 
 /* Lining Definition Style Markup */
 .lining dt,
 .lining dd {
    display: inline;
    margin: 0;
 }
 .lining dt + dt:before,
 .lining dd + dt:before {
    content: "\A";
    white-space: pre;
 }
 .lining dd + dd:before {
    content: ", ";
 }
 .lining dd:before {
    content: ": ";
    margin-left: -0.2em;
 }
 
 /* Dictionary Definition Style Markup */
 .dictionary-style dt {
    display: inline;
    counter-reset: definitions;
 }
 .dictionary-style dt + dt:before {
    content: ", ";
    margin-left: -0.2em;
 }
 .dictionary-style dd {
    display: block;
    counter-increment: definitions;
 }
 .dictionary-style dd:before {
    content: counter(definitions, decimal) ". ";
 }
 
 /* Pagination */
 .pagination {
    margin: 36px auto;
    text-align: center;
 }
 .pagination ul li {
    display: inline-block;
    margin: 0;
    padding: 0;
 }
 .pagination .page-numbers {
    font: 15px/18px 'opensans-bold', sans-serif;
    display: inline-block;
    padding: 6px 10px;
    margin-right: 3px;
    margin-bottom: 6px;
    color: #6E757C;
    background-color: #E6E8EB;
 
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    -ms-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
 
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    -khtml-border-radius: 3px;
    border-radius: 3px;
 }
 .pagination .page-numbers:hover {
    background: #838A91;
    color: #fff;
 }
 .pagination .current,
 .pagination .current:hover {
    background-color: #11ABB0;
    color: #fff;
 }
 .pagination .inactive,
 .pagination .inactive:hover {
    background-color: #E6E8EB;
    color: #A9ADB2;
 }
 .pagination .prev, .pagination .next {}
 
 /*  5. Images  --------------------------------------------------------- */
 
 img {
    max-width: 100%;
    height: auto;
 }
 img.pull-right { margin: 12px 0px 0px 18px; }
 img.pull-left { margin: 12px 18px 0px 0px; }
 
 /*  6. Buttons  --------------------------------------------------------- */
 
 .button,
 .button:visited,
 button,
 input[type="submit"],
 input[type="reset"],
 input[type="button"] {
    font: 16px/30px 'opensans-bold', sans-serif;
    background: #11ABB0;
    display: inline-block;
    text-decoration: none;
    letter-spacing: 0;
    color: #fff;
    padding: 12px 20px;
    margin-bottom: 18px;
    border: none;
    cursor: pointer;
    height: auto;
 
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
 
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    -khtml-border-radius: 3px;
    border-radius: 3px;
 }
 
 .button:hover,
 button:hover,
 input[type="submit"]:hover,
 input[type="reset"]:hover,
 input[type="button"]:hover {
    background: #3d4145;
    color: #fff;
 }
 
 .button:active,
 button:active,
 input[type="submit"]:active,
 input[type="reset"]:active,
 input[type="button"]:active {
    background: #3d4145;
    color: #fff;
 }
 
 .button.full-width,
 button.full-width,
 input[type="submit"].full-width,
 input[type="reset"].full-width,
 input[type="button"].full-width {
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center;
 }
 
 /* Fix for odd Mozilla border & padding issues */
 button::-moz-focus-inner,
 input::-moz-focus-inner {
     border: 0;
     padding: 0;
 }
 
 
 /*  7. Forms  --------------------------------------------------------- */
 
 form { margin-bottom: 24px; }
 fieldset { margin-bottom: 24px; }
 
 input[type="text"],
 input[type="password"],
 input[type="email"],
 textarea,
 select {
    display: block;
    padding: 18px 15px;
    margin: 0 0 24px 0;
    border: 0;
    outline: none;
    vertical-align: middle;
    min-width: 225px;
    max-width: 100%;
    font-size: 15px;
    line-height: 24px;
    color: #647373;
    background: #D3D9D9;
 
 }
 
 /* select { padding: 0;
    width: 220px;
    } */
 
 input[type="text"]:focus,
 input[type="password"]:focus,
 input[type="email"]:focus,
 textarea:focus {
    color: #B3B7BC;
    background-color: #3d4145;
 }
 
 textarea { min-height: 220px; }
 
 label,
 legend {
    font: 16px/24px 'opensans-bold', sans-serif;
    margin: 12px 0;
    color: #3d4145;
    display: block;
 }
 label span,
 legend span {
    color: #8B9798;
    font: 14px/24px 'opensans-regular', sans-serif;
 }
 
 input[type="checkbox"],
 input[type="radio"] {
     font-size: 15px;
     color: #737373;
 }
 
 input[type="checkbox"] { display: inline; }
 
 /* ------------------------------------------------------------------ */
 /* e. Others
 /* ------------------------------------------------------------------ */
 
 /*  1. Clearing
     (http://nicolasgallagher.com/micro-clearfix-hack/
 --------------------------------------------------------------------- */
 
 .cf:before,
 .cf:after {
     content: " ";
     display: table;
 }
 .cf:after {
     clear: both;
 }
 
 /*  2. Misc -------------------------------------------------------- */
 
 .remove-bottom { margin-bottom: 0 !important; }
 .half-bottom { margin-bottom: 12px !important; }
 .add-bottom { margin-bottom: 24px !important; }
 .no-border { border: none; }
 
 .text-center  { text-align: center !important; }
 .text-left    { text-align: left !important; }
 .text-right   { text-align: right !important; }
 .pull-left    { float: left !important; }
 .pull-right   { float: right !important; }
 .align-center {
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: center !important;
 }

 .center {
    width: 100px;
    top: calc(50% - 20px);
    left: calc(50% - 100px);
    position: fixed;
    border: 1px solid #000;
    padding: 20px;
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.text-align-center {
    text-align: center;
}
