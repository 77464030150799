#projects {
    background: #fff;
    padding-top: 40px;
    padding-bottom: 72px;
    overflow: hidden;
}

#projects a,
#projects a:visited {
    color: #11abb0;
}

#projects a:focus,
#projects a:hover {
    color: #313131;
}

#projects h1 {
    font: 42px 'opensans-bold', sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    color: #95a3a3;
    text-decoration: underline;
    background: #ebeeee;
}

#projects > div:nth-child(1) {
    background: #ebeeee;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 48px;
}

#projects h2 {
    font: 24px 'opensans-bold', sans-serif;
    text-transform: capitalize;
    letter-spacing: 1px;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 20px;
}

#projects h3 {
    font: 25px/30px 'opensans-bold', sans-serif;
}

#projects .header-col {
    padding-top: 9px;
}

#projects .main-col {
    padding-right: 10%;
}

.education,
.work {
    margin-bottom: 48px;
    padding-bottom: 24px;
    border-bottom: 1px solid #e8e8e8;
}

#projects .info {
    font: 16px/24px 'raleway-italic', serif;
    color: #6e7881;
    margin-bottom: 18px;
    margin-top: 9px;
    font-style: italic;
}

#projects .info span {
    margin-right: 5px;
    margin-left: 5px;
}

#projects .date {
    font: 15px/24px 'opensans-regular', sans-serif;
    margin-top: 6px;
}

#projects button {
    background: #6e7881;
}

#projects .divider {
    height: min(75px, 10vh);
}

