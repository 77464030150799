#icfts {
    background: #ffffff;
    margin-top: 30px;
    padding-bottom: 66px;
    overflow: hidden;
}

#icfts a,
#icfts a:visited {
    color: #000000;

    font: 1.3vw 'Raleway', sans-serif;
}

#icfts a:hover,
#icfts a:focus {
    color: #000000;
}

#icfts .divider {
    height: min(50px, 3vh);
}

#icfts .text-divider {
    width: 50px;
    display: inline-block;
}

#icfts h1 {
    font: 2.8vw 'Raleway', sans-serif;
    color: #000000;
    margin-bottom: 12px;
    text-align: center;
    font-weight: bold;
}

#icfts h2 {
    font: 2.2vw 'Raleway', sans-serif;
    color: #000000;
    margin-bottom: 12px;
    text-align: center;
    font-weight: bold;
}

#icfts h3 {
    font: 1.3vw 'Raleway', sans-serif;
    color: #000000;
    margin-bottom: 12px;
    text-align: center;
}

#icfts p,
#icfts ul li {
    font: 1.2vw 'Raleway', sans-serif;
    color: #000000;
    font-weight: 300;
    text-align: justify;
}

#icfts .bold {
    font: 1.2vw 'Raleway', sans-serif;
    color: #000000;
    text-align: justify;
    font-weight: bold;
}

#icfts .references {
    font: 0.8vw 'Raleway', sans-serif;
    color: #000000;
    font-weight: 300;
    text-align: justify;
    width: 100%;
}

#icfts .logos {
    position: absolute;
}

#icfts .logos .logo,
#icfts .images-text {
    padding: 20px;
}

#icfts ul {
    list-style-type: disc;
    margin-left: 30px;
}

#icfts .return {
    position: absolute;
    left: 10px;
    top: 10px;
}

