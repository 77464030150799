#study {
    background: #ffffff;
    margin-top: 30px;
    padding-bottom: 66px;
    overflow: hidden;
}

#study a,
#study a:visited {
    color: #000000;

    font: 1.3vw 'Raleway', sans-serif;
}

#study a:hover,
#study a:focus {
    color: #000000;
}

#study .divider {
    height: min(50px, 3vh);
}

#study .text-divider {
    width: 50px;
    display: inline-block;
}

#study h1 {
    font: 2.8vw 'Raleway', sans-serif;
    color: #000000;
    margin-bottom: 12px;
    text-align: center;
    font-weight: bold;
}

#study h2 {
    font: 2.2vw 'Raleway', sans-serif;
    color: #000000;
    margin-bottom: 12px;
    text-align: center;
    font-weight: bold;
}

#study h3 {
    font: 1.3vw 'Raleway', sans-serif;
    color: #000000;
    margin-bottom: 12px;
    text-align: center;
}

#study p, #study ul li {
    font: 1.2vw 'Raleway', sans-serif;
    color: #000000;
    font-weight: 300;
    text-align: justify;
}

#study .bold {
    font: 1.2vw 'Raleway', sans-serif;
    color: #000000;
    text-align: justify;
    font-weight: bold;
}

#study .references {
    font: 0.8vw 'Raleway', sans-serif;
    color: #000000;
    font-weight: 300;
    text-align: justify;
}

#study .logos {
    position: absolute;

}

#study .logos .logo,
#study .images-text {
    padding: 20px;
}

#study ul {
    list-style-type: disc;
    margin-left: 30px;
}

#study .return {
    position: absolute;
    left: 10px;
    top: 10px;
}