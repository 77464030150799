.components {
    background: #ffffff;
    margin-top: 30px;
    padding-bottom: 66px;
    overflow: hidden;
}

.components a,
.components a:visited {
    color: #000000;

    font: 1.3vw 'Raleway', sans-serif;
}

.components a:hover,
.components a:focus {
    color: #000000;
}

.components .divider {
    height: min(50px, 3vh);
}

.components .text-divider {
    width: 50px;
    display: inline-block;
}

.components h1 {
    font: 2.8vw 'Raleway', sans-serif;
    color: #000000;
    margin-bottom: 12px;
    text-align: center;
    font-weight: bold;
}

.components h2 {
    font: 2.2vw 'Raleway', sans-serif;
    color: #000000;
    margin-bottom: 12px;
    text-align: center;
    font-weight: bold;
}

.components h3 {
    font: 1.3vw 'Raleway', sans-serif;
    color: #000000;
    margin-bottom: 12px;
    text-align: center;
}

.components p,
.components ul li {
    font: 1.2vw 'Raleway', sans-serif;
    color: #000000;
    font-weight: 300;
    text-align: justify;
}

.components .bold {
    font: 1.2vw 'Raleway', sans-serif;
    color: #000000;
    text-align: justify;
    font-weight: bold;
}

.components .references {
    font: 0.8vw 'Raleway', sans-serif;
    color: #000000;
    font-weight: 300;
    text-align: justify;
    width: 100%;
}

.components .logos {
    position: absolute;
}

.components .logos .logo,
.components .images-text {
    padding: 20px;
}

.components .logos .logo {
    max-width: max(250px, 15vw);
    max-height: max(250px, 10vh);
}

.components ul {
    list-style-type: disc;
    margin-left: 30px;
}

.components .return {
    position: absolute;
    left: 10px;
    top: 10px;
}

